import WhatsappFAB from '@web/atomic/atm.whatsapp-fab/whatsapp-fab.component';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import CleanLayout from '@lp-root/src/components/org.layout/clean-layout.component';
import OrderSuccess from '@lp-root/src/modules/order-success/order-success.component';
import { appPaths } from '@lp-root/src/utils/path';
import { PageProps } from '@lp-src/utils/local-types';
import { Body } from '@web/atomic';
import { useQueryParams } from '@web/atomic/obj.custom-hooks/query-params';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';

interface InstrucoesB2bPageQueryParams {
  description?: string;
  gender?: 'male' | 'female';
  name?: string;
}

const InstrucoesB2bPage: React.FunctionComponent<PageProps> = (props) => {
  const queryParams = useQueryParams<InstrucoesB2bPageQueryParams>();
  const socialMedia = {
    canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, appPaths.instructions.path),
    title: 'Prazer, Sônia',
    description: 'Conheça a Sônia, nossa coach digital que te acompanhará durante todo o programa',
  };

  return (
    <CleanLayout>
      <SEO socialMedia={socialMedia} />
      <OrderSuccess headline={'Prepare-se para o fim dos seus problemas com o sono!'}>
        {queryParams.name && queryParams.gender && (
          <Body>
            Vimos que você ficou sabendo do programa pel{queryParams.gender === 'male' ? 'o' : 'a'} {queryParams.name}. Utilize seu código
            dentro do app para receber os benefícios da nossa parceria.
          </Body>
        )}
      </OrderSuccess>
      <WhatsappFAB kind="support" />
    </CleanLayout>
  );
};

export default InstrucoesB2bPage;

export const query = graphql`
  query InstrucoesB2bQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
